<template>
  <div class="fill-height">

    <AppBar />

    <div class="fill-height d-flex align-center justify-center">

      <div class="text-center">
        <v-icon size="200">
          {{ $route.meta.icon }}
        </v-icon>
        <br>
        TBD: {{ $route.meta.title }}
      </div>

    </div>

  </div>
</template>

<script>
import AppBar from '@/components/app-bar'

export default {
  name: 'DummyPage',
  components: {
    AppBar
  }
}
</script>
